.header {
  padding-top: 3rem;
  padding-bottom: 5rem;

}

@media (max-width: 991.98px) {
  .header {
    .section-title {
      font-size: 24px;
    }
  }
}

@media (max-width: 767.98px) {
  .header {
    padding: 2rem 15px;

    .title-wrapper {
      flex-direction: column;

      img {
        max-width: 80px;
        display: block;
        margin-bottom: 1rem;
      }

      span {
        display: none;
      }
    }

    .section-title {
      font-size: 22px;
    }

  }
}