footer {
  font-size: 15px;
  color: $color-footer;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-bottom {
  background-color: $color-bg-footer-bottom;
  display: flex;
  justify-content: center;
  padding: 1.75rem 0;
}

@media (max-width: 575.98px) {
  .footer-bottom {
    font-size: 0.8em;
    padding: 1rem;
    text-align: center;
  }
}