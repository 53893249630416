@import "styles/fonts";
@import "styles/variables";

@import "styles/reset";
@import "styles/footer";

// Pages
@import "styles/pages/demos";

// Layout
@import "styles/header";
@import "styles/titles";

// Components
@import "styles/components/list";
@import "styles/components/drawer";
@import "styles/components/modal";
@import "styles/components/forms";
@import "styles/components/buttons";

body {
  font-family: Montserrat;
  color: $color-primary;
}
