.list {
  list-style-type: none;
  padding: 0;
  padding-bottom: 80px;
}

.list-item {
  margin-bottom: 1rem;
}

.list-item-link {
  border-radius: 4px;
  color: $color-primary;
  text-decoration: none;
  font-weight: 500;
  background-color: $color-secondary;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.list-item-button {
  cursor: pointer;
  color: #fff;
  background-color: $color-primary;
}

.list-title {
  font-weight: 700;
  margin-right: 1rem;
}

.list-actions {
  cursor: pointer;
  display: flex;

  img {
    margin-left: 1rem;
  }
}


@media (max-width: 767.98px) {
  .list {
    padding-bottom: 50px;

    .list-item {
      font-size: 14px;
      margin-bottom: 0.75rem;
    }

    .list-item-link {
      padding: 0.75rem;
    }
  }
}