#demos {

  
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      width: 108px;
    }
  }
}
