#modal {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparentize(#f6f6f6, 0.1);
  z-index: -1;
  transition: 200ms all ease-in;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    z-index: 1;
    opacity: 1;
    transition: 200ms all ease-out;
  }

  .modal-card {
    padding: 3rem;
    background-color: #fff;
    width: 500px;
    transform: translatey(-200px);
    transition: 200ms all ease-in;
    text-align: center;
    border-radius: 8px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);

    &.active {
      transform: translateY(0);
      transition: 200ms all ease-out;
    }
  }

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .modal-legend {
    font-size: 16px;
    margin-bottom: 3rem;
  }

  .modal-buttons {
    .btn:first-child {
      margin-right: 2.5rem;
    }
  }

}


@media (max-width: 600px) {
  #modal {
    .modal-card {
      padding: 2rem 1rem;
      width: 100%;
      margin-left: 15px;
      margin-right: 15px;
    }

    .modal-title {
      font-size: 22px;
      margin-bottom: 2rem;
    }

    .modal-legend {
      font-size: 14px;
      margin-bottom: 2rem;
    }

    .modal-buttons {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .btn:first-child {
        margin: 0;

      }
    }
  }
}