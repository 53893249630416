.btn {
  font-size: 13px;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: none;
}

.btn-success {
  font-weight: 600;
  text-transform: uppercase;
  background: $color-primary;
  padding: 0.65rem 4rem;
  border: 1px solid $color-primary;
  border-radius: 12px;
  box-shadow: none;
  width: 75%;
  font-size: 18px;
  display: block;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background: transparentize($color-primary, 0.1);
    border: 1px solid transparentize($color-primary, 0.1);
    text-decoration: none;
  }
}

.btn-primary {
  background: $color-primary;
  border: 1px solid $color-primary;

  &:hover {
    background: transparentize($color-primary, 0.1);
    border: 1px solid transparentize($color-primary, 0.1);
    text-decoration: none;
  }
}